import React from 'react';
import "./PopupLoading.css";

const PopupLoading = ({ isOpen }) => {
  return isOpen ? (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="loading-circle"></div>
        <p>Loading...</p>
      </div>
    </div>
  ) : null;
};

export default PopupLoading;