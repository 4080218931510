import React, { useState, useEffect } from 'react';
import './Settings.css';
import { getAnalyticsSettings, updateAnalyticsSettings } from '../../api/api';
import { useNavigate } from 'react-router-dom';
import SettingsPopup from '../../components/SettingsPopup/SettingsPopup';

const Settings = ({setLoading, clearUser}) => {


  const [analyticsSettings, setAnalyticsSettings] = useState(null);
  const [functionCalled, setFunctionCalled] = useState(false);
  const [popupIsOpen, setOpenPopup] = useState(false);

  useEffect(()=>{
    if (!functionCalled) {
      setFunctionCalled(true);
      getInfo();
    }
  });
  
const navigate = useNavigate();

const getInfo = async ()=>{  
  setLoading(true);
  try{
    const response = await getAnalyticsSettings();
    setAnalyticsSettings(response.data);
    console.log(response.data);
  }
  catch(error)
  {
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    } 
    setLoading(false);
  }
  finally{
    setLoading(false);
  }
}

const updateInfo = async (settings)=>{  
  setLoading(true);
  try{
    await updateAnalyticsSettings(settings);
    getInfo();
  }
  catch(error)
  {
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    } 
    setLoading(false);
  }
  finally{
    setLoading(false);
  }
}

const openPopup = ()=>{
  setOpenPopup(true);
}

const closePopup = ()=>{
  setOpenPopup(false);
}



  if (analyticsSettings === null)
  {
    return (null);
  }
  return (
    <div className="tab-navigation-page" style={{display: "block"}}>
        <div className="settings-group">
          <div className="settings-tittle-group">
            <h1 style={{textAlign:'left', marginBottom:'32px'}}>Аналитика</h1>
            <button className='setting-edit-button' onClick={openPopup}>Редактировать</button>
            </div>
            <div className="setting-parameter">
                <label className="setting-parameter-name">Налоги</label>
                <label className="setting-parameter-value">{analyticsSettings.taxes}%</label>
            </div>
            <div className="setting-parameter">
                <label className="setting-parameter-name">Эквайринг</label>
                <label className="setting-parameter-value">{analyticsSettings.acquiring}%</label>
            </div>
            <div className="setting-parameter">
                <label className="setting-parameter-name">Зарплата</label>
                <label className="setting-parameter-value">{analyticsSettings.salary}%</label>
            </div>
            <div className="setting-parameter">
                <label className="setting-parameter-name">Кредит</label>
                <label className="setting-parameter-value">{analyticsSettings.credit}%</label>
            </div>
            <div className="setting-parameter">
                <label className="setting-parameter-name">Стоимость хранения</label>
                <label className="setting-parameter-value">{analyticsSettings.warehouseCost} р./сут.</label>
            </div>
            <div className="setting-parameter">
                <label className="setting-parameter-name">Срок хранения</label>
                <label className="setting-parameter-value">{analyticsSettings.warehousePeriod} дн.</label>
            </div>
        </div>
        <SettingsPopup isOpen={popupIsOpen} settings={analyticsSettings} onSubmit={updateInfo} onClose={closePopup}/>
    </div>
  );
};

export default Settings;