import logo from './logo.svg';
import { BrowserRouter, Route, Routes, useLocation  } from 'react-router-dom';
import Login from './pages/Login/Login';
import React, { useState, useEffect } from 'react';
import Home from './pages/Home/Home';
import AuthRoute from './services/auth/AuthRoute';
import Root from './pages/Root/Root';
import SideBar from './components/Sidebar/SideBar';
import Integration from './pages/Intragrion/Integration';
import Reports from './pages/Reports/Reports';
import Goods from './pages/Goods/Goods';
import './App.css';
import PopupLoading from './components/PopupLoading/PopuipLoading';
import FileUploadPopup from './components/FileUploadPopup/FileUploadPopup.';
import { setAuthToken, initializeApi } from './api/api';
import Settings from './pages/Settings/Settings';

const App = () => {

  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // При обновлении состояния токена и userId, сохраняем их в локальное хранилище
    localStorage.setItem('token', token);
    setAuthToken(token);

    initializeApi(clearUser);
  }, [token]);

  document.title = "BRADE аналитика";

  const setLoading = (value) =>{
    setIsLoading(value);
  }

  const saveAuth = (token) => {
    setToken(token);
    setAuthToken(token);
  };

  const clearUser = () => {    
    localStorage.setItem('token', null);
    setToken(null);
    setAuthToken(null);
  };

  return (
    <BrowserRouter style={{ display: "flex", height: "100%" }}>
    <div style={{ display: "flex", height: "100%" }}>
    <SideBar clearUser={clearUser}/>
    <div style={{left: "200px", width:"100%"}}>
    <Routes>
      <Route path='/login' element={<Login saveAuth={saveAuth} token={token} setLoading={setLoading}/>}/>
      <Route path='/' element={<AuthRoute token={token}/>}>
        <Route path='/' element={<Home/>}/>
        <Route path='/integration' element={<Integration/>}/>
        <Route path='/reports' element={<Reports setLoading={setLoading} clearUser={clearUser}/>}/>
        <Route path='/goods' element={<Goods setLoading={setLoading} clearUser={clearUser}/>}/>
        <Route path='/settings' element={<Settings setLoading={setLoading} clearUser={clearUser}/>}/>
      </Route>
    </Routes>
    </div>
    </div>
    <PopupLoading isOpen={isLoading}/>
    <FileUploadPopup isOpen={false}/>
    </BrowserRouter>
  );
};


export default App;
