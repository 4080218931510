import axios from 'axios';

const BASE_URL = 'https://apis.mpbrade.ru'; //'http://localhost:7831' //'https://apis.mpbrade.ru'//'http://31.129.104.144:25345/'; // Замените на адрес вашего REST API

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});


let logOutFunction;

const initializeApi = ({ setAuthTokenFromApp }) => {
  logOutFunction = setAuthTokenFromApp;
};

const getCurrentDateAsString = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const AuthError = () => {
  logOutFunction(); // Вызываем функцию logOutFunction
};

const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};


const loginRequest = async (login, password) => {
  try {
    const response = await api.post('/token',{login, password}, {headers: { 'Content-Type': 'multipart/form-data' }});
    return response;
  } catch (error) {
    console.log(error);
    throw new Error('Ошибка аутентификации');
  }
};

const getUserInformation = async () => {
  try {
    const response = await api.get('/api/user-information');
    return response;
  } catch (error) {
    throw error;
  }
};

const getProductList = async () => {
  try {
    const response = await api.get('/api/info/products');
    return response;
  } catch (error) {
    throw error;
  }
};


const getStockReport = async () => {
  try {
    const response = await api.get('/stocksreport', { responseType: 'blob' });
    const blob = new Blob([response.data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Report_'+getCurrentDateAsString()+'.xlsx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return response;
  } catch (error) {
    throw error;
  }
};

const getProductTemplate = async () => {
  try {
    const response = await api.get('/api/producs/list/template', { responseType: 'blob' });
    const blob = new Blob([response.data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Product_Template.xlsx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return response;
  } catch (error) {
    throw error;
  }
};

const getProductDataXcel = async () => {
  try {
    const response = await api.get('/api/producs/list/get', { responseType: 'blob' });
    const blob = new Blob([response.data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Product_list_+'+getCurrentDateAsString()+'.xlsx');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return response;
  } catch (error) {
    throw error;
  }
};

const uploadPrice = async (file) => {
  try {
    const formData = new FormData();
    formData.append('uploadedFile', file);

    console.log('file: ' + file.name);
    const response = await api.post('/api/producs/list/edit', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    console.log('File uploaded successfully:', response.data);
    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

const uploadGoods = async (file) => {
  try {
    const formData = new FormData();
    formData.append('uploadedFile', file);
    
    console.log('file: ' + file.name);
    const response = await api.post('/api/producs/list/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    console.log('File uploaded successfully:', response.data);
    return response;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

const uploadGood = async (good) =>{
  try {
    const response = await api.post('/api/product/add', {
      Id: good.productId,
      Name: good.name,
      Barcode: good.barcode,
      OfferId: good.offerId, 
      SellerCost: good.sellerCost, 
      Weight: good.weight, 
      Volume: good.volume,
      Width: good.width,
      Height: good.height,
      Lenght: good.lenght,
      AdsCostBuy: good.adsCost
    });
    return response;
  } catch (error) {
    console.error('Error upload product:', error);
    throw error;
  }
}

const getUser = async()=>{
  try {
    const response = await api.get('/api/user-information');
    return response;
  } catch (error) {
    throw error;
  }
}

const editGood = async (good) =>{
  try {
    const response = await api.post('/api/product/edit', {
      Id: good.productId,
      Name: good.name,
      Barcode: good.barcode,
      OfferId: good.offerId, 
      SellerCost: good.sellerCost, 
      Weight: good.weight, 
      Volume: good.volume,
      Width: good.width,
      Height: good.height,
      Lenght: good.lenght,
      AdsCostBuy: good.adsCost
    });
    return response;
  } catch (error) {
    console.error('Error upload product:', error);
    throw error;
  }
}

const getAnalyticsSettings = async () =>{
  try {
    const response = await api.get('/api/analytics/info');
    return response;
  } catch (error) {
    console.error('Error get analytics settings:', error);
    throw error;
  }
}

const updateAnalyticsSettings = async (settings) =>{
  try {
    const response = await api.post('/api/analytics/edit', {
      taxes: settings.taxes,
      acquiring: settings.acquiring,
      credit: settings.credit,
      salary: settings.salary,
      warehouseCost: settings.warehouse_cost,
      warehousePeriod: settings.warehouse_period
    });
    return response;
  } catch (error) {
    console.error('Error edit analytics settings:', error);
    throw error;
  }
}

// Другие функции для выполнения запросов к вашему API

export { loginRequest as login , setAuthToken, getProductList, initializeApi, AuthError, getUserInformation, getStockReport, uploadGoods,
   uploadPrice,
   getAnalyticsSettings,
   updateAnalyticsSettings,
   uploadGood,
   editGood,
   getProductTemplate,
   getProductDataXcel,
   getUser
  };