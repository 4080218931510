import React, { useState, useEffect } from 'react';
import './SettingsPopup.css';

const SettingsPopup = ({ isOpen, onClose, onSubmit, settings }) => {
  const [taxes, setTaxes] = useState('');
  const [acquiring, setAcquiring] = useState('');
  const [salary, setSalary] = useState('');
  const [credit, setCredit] = useState('');
  const [warehouse_cost, setWarehouseCost] = useState('0');
  const [warehouse_period, setWarehousePeriod] = useState('0');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ taxes, acquiring, salary, credit, warehouse_cost, warehouse_period });
    onClose();
  };

  useEffect(() => {
    if (settings)
    {
      setTaxes(settings.taxes);
      setAcquiring(settings.acquiring);
      setSalary(settings.salary);
      setCredit(settings.credit);
      setWarehouseCost(settings.warehouseCost);
      setWarehousePeriod(settings.warehousePeriod);
    }
  },[settings])

  return (
    <div className={`settings-popup ${isOpen ? 'open' : ''}`}>
      <div className="settings-popup-content">
        <span className="settings-popup-close-button" onClick={onClose}>X</span>
        <form onSubmit={handleSubmit}>
          <div className="settings-popup-form-group">
            <label htmlFor="taxes" className="settings-popup-label">Налоги:</label>
            <input type="text" id="taxes" className="settings-popup-input" value={taxes} onChange={(e) => setTaxes(e.target.value)} />
          </div>
          <div className="settings-popup-form-group">
            <label htmlFor="acquiring" className="settings-popup-label">Эквайринг:</label>
            <input type="text" id="acquiring" className="settings-popup-input" value={acquiring} onChange={(e) => setAcquiring(e.target.value)} />
          </div>
          <div className="settings-popup-form-group">
            <label htmlFor="salary" className="settings-popup-label">Зарплата:</label>
            <input type="text" id="salary" className="settings-popup-input" value={salary} onChange={(e) => setSalary(e.target.value)} />
          </div>
          <div className="settings-popup-form-group">
            <label htmlFor="credit" className="settings-popup-label">Кредит:</label>
            <input type="text" id="credit" className="settings-popup-input" value={credit} onChange={(e) => setCredit(e.target.value)} />
          </div>
          <div className="settings-popup-form-group">
            <label htmlFor="warehouse-cost" className="settings-popup-label"> Стоимость хранения:</label>
            <input type="text" id="warehouse-cost" className="settings-popup-input" value={warehouse_cost} onChange={(e) => setWarehouseCost(e.target.value)} />
          </div>
          <div className="settings-popup-form-group">
            <label htmlFor="warehouse-period" className="settings-popup-label">Срок хранения:</label>
            <input type="text" id="warehouse-period" className="settings-popup-input" value={warehouse_period} onChange={(e) => setWarehousePeriod(e.target.value)} />
          </div>
          <button type="submit" className="settings-popup-submit-button">Сохранить</button>
        </form>
      </div>
    </div>
  );
};

export default SettingsPopup;