import React, { useState } from 'react';
import './Reports.css';
import { getStockReport } from '../../api/api';
import { useNavigate } from 'react-router-dom';

const Reports = ({setLoading, clearUser}) => {

  
const navigate = useNavigate();

const stockReport = async ()=>{

  setLoading(true);
  try{
    const response = await getStockReport();
  }
  catch(error)
  {
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    } 
    setLoading(false);
  }
  finally{
    setLoading(false);
  }
}

  return (
    <div className="tab-navigation-page" id="marketplace-page" style={{display: "block"}}>
            <h1>Отчеты</h1>
            <div className="main-content">
            <div className="report-category">Остатки</div>
            <button className="button-report" onClick={stockReport}>&gt;&gt; Отчет по остаткам</button>
            </div>
        </div>
  );
};

export default Reports;