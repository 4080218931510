// src/pages/Login/Login.js

import React, { useState } from 'react';
import './Login.css'; // Подключаем файл стилей для этой страницы (если требуется)
import { login } from '../../api/api';
import VideoBackground from '../../components/VideoBackground';
import { Navigate } from 'react-router-dom';

const Login = ({saveAuth, token, setLoading}) => {
  // Состояния для хранения значений полей ввода
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Обработчик для отправки формы
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try{      
    const response = await login(email, password);
    console.log(response.data);
   // const jsonData = JSON.parse(response.data);
    saveAuth(response.data.access_token);
    }
    catch(error)
    {      
    setLoading(false);
    }
    finally{
      setLoading(false);
    }
    // Дополнительная логика...
  };

  if ((token != null)) {
    return <Navigate to="/" />;
  }


  return (
    <VideoBackground>
    <div className="login-container">
      <h2 className="login-header">ВХОД В СИСТЕМУ</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>ЛОГИН</label>
          <input type="login" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label>ПАРОЛЬ</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        <button type="submit">ВОЙТИ</button>
      </form>
    </div>
    </VideoBackground>
  );
};

export default Login;