import React, { useState, useEffect } from 'react';
import './Goods.css';
import GoodList from '../../components/GoodsList/GoodList';
import { getProductList, uploadGoods, uploadPrice, uploadGood, editGood, getProductTemplate, getProductDataXcel } from '../../api/api';
import { useNavigate } from 'react-router-dom';
import FileUploadPopup from '../../components/FileUploadPopup/FileUploadPopup.';
import GoodUploadPopup from '../../components/GoodPopup/GoodPopup';
import GoodsXcelUpload from '../../components/GoodDropDown/GoodsXcelUpload';
const Goods = ({setLoading, clearUser}) => {

  const navigate = useNavigate();

  const [products, setProducts] = useState(null);
  const [uploadPopupOpen, setUploadPopupOpen] = useState(false);
  const [uploadRequest, setUploadRequest] = useState(null);
  const [goodPopupOpened, setGoodPopupOpened] = useState(false);
  const [goodPopupEditMode, setGoodPopupEditMode] = useState(false);
  const [goodPopupProduct, setgoodPopupProduct] = useState(null);
  const [goodPopupOnSubmit, setGoodPopupOnSubmit] = useState(null);
  const [functionCalled, setFunctionCalled] = useState(false);

  useEffect(()=>{
    if (!functionCalled) {
      loadProduct();
      setFunctionCalled(true);
    }
  });

  const loadProduct = async () =>{
    setLoading(true);
    try
    {      
    const loadedProducts = await getProductList();  
    setProducts(loadedProducts.data);
    }
    catch (error)
    {      
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    }
    setLoading(false);
    }
    finally
    {      
    setLoading(false);
    }
  }

  const downloadTemplate = async ()=>{
    setLoading(true);
    try
    {      
      await getProductTemplate();
    }
    catch (error)
    {      
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    }
    setLoading(false);
    }
    finally
    {      
    setLoading(false);
    }
  }

  const downloadProducts = async ()=>{
    setLoading(true);
    try
    {      
      await getProductDataXcel();
    }
    catch (error)
    {      
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    }
    setLoading(false);
    }
    finally
    {      
    setLoading(false);
    }
  }

  const uploadGoodsFile = async (file)=>{
    setLoading(true);
    try
    {      
      await uploadGoods(file);
    }
    catch (error)
    {      
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    }
    setLoading(false);
    }
    finally
    {      
    setLoading(false);
    loadProduct();
    }
  }

  const uploadPriceFile = async (file)=>{
    setLoading(true);
    try
    {      
      await uploadPrice(file);
    }
    catch (error)
    {      
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    }
    setLoading(false);
    }
    finally
    {      
    setLoading(false);
    loadProduct();
    }
  }

  const uploadInfo = async (request, file) =>{

  }

  const UploadNewProduct = async (product) =>{

    setLoading(true);
    try
    {      
    await uploadGood(product);
    }
    catch (error)
    {      
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    }
    setLoading(false);
    }
    finally
    {      
    setLoading(false);
    loadProduct();
    }

    console.log("UploadProduct: ", product);
  }

  const EditProduct = async (product) =>{
    setLoading(true);
    try
    {      
    await editGood(product);
    }
    catch (error)
    {      
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    }
    setLoading(false);
    }
    finally
    {      
    setLoading(false);
    loadProduct();
    }

  }

  const uploadPricePopup = ()=>{    
    setUploadPopupOpen(true);
    setUploadRequest((file) => uploadPriceFile);
  }

  const uploadGoodPopup = ()=>{
    setUploadPopupOpen(true);
    setUploadRequest((file) => uploadGoodsFile);
  }

  const closeUploadPopup= ()=>{
    setUploadPopupOpen(false);
  }

  const openAddGood = ()=>{

    const handleEditProduct = (newValue) => {
      UploadNewProduct(newValue);
    };
    setGoodPopupOnSubmit(()=>handleEditProduct);
    setGoodPopupEditMode(false);
    setgoodPopupProduct(null);
    setGoodPopupOpened(true);
  }

  const openEditGood = (product)=>{    

    const handleEditProduct = (newValue) => {
      EditProduct(newValue);
    };

    setGoodPopupOnSubmit(()=>handleEditProduct);
    setGoodPopupEditMode(true);
    setgoodPopupProduct(product);
    setGoodPopupOpened(true);
  }

  const closeGoodPopup = ()=>{  
    setGoodPopupEditMode(false);
    setgoodPopupProduct(null);  
    setGoodPopupOpened(false);
  }

  return (
    <div className="tab-navigation-page" id="marketplace-page" style={{display: "block", textAlign:'left'}}>
            <h1>Товары</h1>
            <div className="main-content">
                <div className="goods-header-panel">
                    
                <button className="product-upload-button" onClick={loadProduct}>ОБНОВИТЬ</button>
                <button className="product-upload-button" onClick={openAddGood}>+</button>
                <GoodsXcelUpload onDownloadTemplate={downloadTemplate} onDownloadProducts={downloadProducts} onUploadProducts={uploadGoodPopup} onEditProducts={uploadPricePopup}/>
                <div className='product-upload-group-button'>                    
                <button className="product-view-select" style={{marginLeft: "0px",marginRight: "2px", padding:"0px"}}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M200-540q-33 0-56.5-23.5T120-620v-140q0-33 23.5-56.5T200-840h140q33 0 56.5 23.5T420-760v140q0 33-23.5 56.5T340-540H200Zm0 420q-33 0-56.5-23.5T120-200v-140q0-33 23.5-56.5T200-420h140q33 0 56.5 23.5T420-340v140q0 33-23.5 56.5T340-120H200Zm420-420q-33 0-56.5-23.5T540-620v-140q0-33 23.5-56.5T620-840h140q33 0 56.5 23.5T840-760v140q0 33-23.5 56.5T760-540H620Zm0 420q-33 0-56.5-23.5T540-200v-140q0-33 23.5-56.5T620-420h140q33 0 56.5 23.5T840-340v140q0 33-23.5 56.5T760-120H620Z"/></svg>
                </button>
                <button className="product-view-select" style={{marginLeft: "0px", padding:"0px"}} disabled>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" ><path d="M360-160h440q33 0 56.5-23.5T880-240v-80H360v160ZM80-640h200v-160H160q-33 0-56.5 23.5T80-720v80Zm0 240h200v-160H80v160Zm80 240h120v-160H80v80q0 33 23.5 56.5T160-160Zm200-240h520v-160H360v160Zm0-240h520v-80q0-33-23.5-56.5T800-800H360v160Z"/></svg>
                </button>
                </div>
                </div>
                <GoodList products={products} onProductEdit={openEditGood}/>
            </div>
            <FileUploadPopup isOpen={uploadPopupOpen} onClose={closeUploadPopup} onFileUpload={uploadRequest}/>
            <GoodUploadPopup isOpen={goodPopupOpened} onClose={closeGoodPopup} isEdit={goodPopupEditMode} existedObject={goodPopupProduct} onSubmitClicked={goodPopupOnSubmit}/>
        </div>
  );
};

export default Goods;