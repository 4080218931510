import React, { useState } from 'react';
import './FileUploadPopup.css';

const FileUploadPopup = ({ isOpen, onClose, onFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (selectedFile) {
      onFileUpload(selectedFile);
      setSelectedFile(null);
      onClose();
    }
  };

  return isOpen ? (
    <div className="file-upload-popup">
      <div className="file-upload-content">
        <h2>Выберите файл</h2>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  ) : null;
};

export default FileUploadPopup;