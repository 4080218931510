import React, { useState } from 'react';
import './Integration.css';

const Integration = () => {

  return (
    <div className="tab-navigation-page" id="marketplace-page" style={{display: "block"}}>
            <h1>Интеграции</h1>
            <div className="main-content">
                <div className="report-category">Маркетплейсы</div>
                <div className="category-items" style={{display: "flex", gap: "14px", paddingLeft: "14px"}}>
                    <div className="integration-block">
                        <div className="integration-block-image">
                          
                        <div className="integration-block-holder">
                          <img src="img/ozon-image.jpg" style={{objectFit: "cover"}}/>
                          </div>
                          </div>
                        <p className="integration-block-tittle">Ozon</p>
                    </div>
                    <div className="integration-block">
                        <div className="integration-block-image">
                          <div className="integration-block-holder">
                          <img src="img/wb-image.jpg" style={{objectFit: "cover"}}/>
                          </div>
                          </div>
                        <p className="integration-block-tittle">WB</p>
                    </div>
                </div>
                <div className="report-category">Склады</div>
                <div className="category-items" style={{display: "flex", gap: "14px", paddingLeft: "14px"}}>
                    <div className="integration-block">
                        <div className="integration-block-image">
                          <div className="integration-block-holder">
                          <img src="img/ware-house-icon.png" style={{objectFit: "cover"}}/>
                          </div>
                          </div>
                        <p className="integration-block-tittle">Платформа</p>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default Integration;