import React from 'react';
import './SideBar.css';
import OrganizationInfo from '../OrganizationInfo/OrganizationInfo';
import { useNavigate, useLocation } from "react-router-dom";

const SideBar = ({children, clearUser}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const shouldRender = location.pathname != '/login';

  const handleButtonClick = (path) => {
    navigate(path);
  };

  const logOut = () => {
    clearUser();
    navigate("/login");
  };
  
  return shouldRender ? (
    <div className="sidebar-container">
        <OrganizationInfo clearUser={clearUser}/>
        <button className="sidebar-button" onClick={() => handleButtonClick("/integration")}>Интеграции</button>
        <button className="sidebar-button" onClick={() => handleButtonClick("/goods")}>Товары</button>
        <button className="sidebar-button" onClick={() => handleButtonClick("/reports")}>Отчеты</button>
        <button className="sidebar-button" onClick={() => handleButtonClick("/team")}>Команда</button>
        <button className="sidebar-button" onClick={() => handleButtonClick("/settings")}>Настройки</button>
        <button className="sidebar-button" style={{marginTop: "40px"}} onClick={logOut}>Выйти</button>
    </div>
  ): null;
};

export default SideBar;