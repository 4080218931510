import React from 'react';
import './VideoBackground.css';

const VideoBackground = ({children}) => {
  return (
    <div className="video-background">
      <video autoPlay muted loop className="video-background__video">
        <source src="videos/background.mp4" type="video/mp4" />
      </video>
      <div className="video-background__content">
        {children}
      </div>
    </div>
  );
};

export default VideoBackground;