import React, { useState } from 'react';
import './GoodsXcelUpload.css'; // Подключите файл стилей для меню

const GoodsXcelUpload = ({ onDownloadTemplate, onDownloadProducts, onUploadProducts, onEditProducts }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown-menu">
      <button className="dropdown-menu-toggle" onClick={toggleMenu}>
        XCEL
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M480-360 280-560h400L480-360Z"/></svg>
      </button>
      {isOpen && (
        <ul className="dropdown-menu-list">
          <li className="dropdown-menu-item" onClick={onDownloadTemplate}>
            Скачать шаблон
          </li>
          <li className="dropdown-menu-item" onClick={onDownloadProducts}>
            Скачать товары
          </li>
          <li className="dropdown-menu-item" onClick={onUploadProducts}>
            Загрузить товары
          </li>
          <li className="dropdown-menu-item" onClick={onEditProducts}>
            Обновить товары
          </li>
        </ul>
      )}
    </div>
  );
};

export default GoodsXcelUpload;