import React,{useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import './OrganizationInfo.css';
import { getUser } from '../../api/api';

const OrganizationInfo = ({clearUser}) => {

  
  const navigate = useNavigate();
  const [functionCalled, setFunctionCalled] = useState(false);
  const [userInfo, setUser] = useState(null);

  useEffect(()=>{
    if (!functionCalled) {
      loadProduct();
    }
  });

  const loadProduct = async () =>{
    try
    {      
    const loadedProducts = await getUser();  
    const value = await loadedProducts;
    const userResponseData = await JSON.parse(value.data);
    setUser(userResponseData);
    }
    catch (error)
    {      
    if (error.response && error.response.status === 401) {
      clearUser();
      navigate('/login');      
    }
    }
    finally
    {     
      setFunctionCalled(true); 
    }
  }

  return (
    <div className="organization-info">
            <div className="organization-info-content">
                <div className="organization-name-avatar">
                    <img src="/img/brade-logo.jpg" style={{objectFit:"cover"}}/>
                </div>
                <p className="organization-name-text">{userInfo !== null ? userInfo.company : "..."}</p>
                <p className="organization-name-user-text">{userInfo !== null ? (userInfo.surname + "  " + userInfo.name) : "loading..."}</p>
            </div>
        </div>
  );
};

export default OrganizationInfo;